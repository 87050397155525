import {FC, PropsWithChildren} from 'react';
import styled from 'styled-components';
import {spacing, colors, grey} from '@hy-vee/themes';

import {useIsMobile} from 'client/hooks/use-is-mobile';

import {pulseBackground} from '../../../styles/loading-skeletons';
import SwimlaneSkeleton from '../product/loading/swimlane-skeleton';
import {StyledBreadcrumbLink} from '../product/loading/product-details-skeleton';

const StyledLoadingPageWrapper = styled.div`
    display: flex;
    align-self: center;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
`;

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${colors.grey[100]};
    padding: 20px 0 50px 0;
    max-width: 1204px;
    width: 100%;
    margin: 0 auto;
`;

const StyledMobileContainer = styled(StyledContainer)`
    padding: 0;
    margin: ${spacing.medium} 0 0;
`;

const StyledCategoryHeader = styled.div`
    background-color: ${colors.grey[100]};
    animation: ${pulseBackground} 1s infinite ease-in-out;
    height: 3em;
    width: 20em;
`;

const StyledMobileCategoryHeader = styled(StyledCategoryHeader)`
    margin-top: ${spacing.medium};
    margin-left: ${spacing.medium};
`;

const StyledCategoryBreadcrumbs = styled(StyledBreadcrumbLink)`
    margin: ${spacing.small} 0 ${spacing.medium} 0 !important;
    width: 8em;
`;

const StyledMobileCategoryBreadcrumbs = styled(StyledCategoryBreadcrumbs)`
    margin-left: ${spacing.medium} !important;
`;

const StyledSwimlanesContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const StyledCardGridSkeleton = styled.div`
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid ${grey[300]};
    background: ${grey[100]};

    @media (min-width: 600px) {
        border-top: none;
        justify-content: center;
        margin: auto 36px;
    }
`;

const StyledImageSkeleton = styled.div`
    background-color: ${colors.grey[100]};
    animation: ${pulseBackground} 1s infinite ease-in-out;
    height: 52px;
    width: 52px;
    border-radius: 100%;

    @media (min-width: 600px) {
        height: 148px;
        width: 148px;
        margin: auto;
    }
`;

const StyledCardSkeleton = styled.div`
    display: block;
    width: 175px;
    margin: ${spacing.large} ${spacing.large} 12px;
`;

const StyledCategoryTextSkeleton = styled.div`
    background-color: ${colors.grey[100]};
    animation: ${pulseBackground} 1s infinite ease-in-out;
    height: 2em;
    width: 11em;
    margin-top: ${spacing.medium};
`;

const StyledChildCategoryTextSkeleton = styled(StyledCategoryTextSkeleton)`
    height: 1em;
    width: 11em;
`;

const StyledMobileCardSkeleton = styled.div`
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid ${grey[300]};
    width: 100%;
    background-color: ${colors.grey[100]};
    animation: ${pulseBackground} 1s infinite ease-in-out;
    height: 85px;
`;

const StyledDesktopHeaderSkeleton = styled.div`
    padding-left: 66px;
`;

const CardSkeleton: FC<PropsWithChildren<PropsWithChildren>> = () => (
    <StyledCardSkeleton>
        <StyledImageSkeleton />
        <StyledCategoryTextSkeleton />
        <StyledChildCategoryTextSkeleton />
    </StyledCardSkeleton>
);

const DesktopHeaderSkeleton: FC<PropsWithChildren<PropsWithChildren>> = () => (
    <StyledContainer data-testid="desktop-header-skeleton">
        <StyledDesktopHeaderSkeleton>
            <StyledCategoryBreadcrumbs />
            <StyledCategoryHeader />
        </StyledDesktopHeaderSkeleton>
        <StyledCardGridSkeleton>
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
        </StyledCardGridSkeleton>
    </StyledContainer>
);

const MobileHeaderSkeleton: FC<PropsWithChildren<PropsWithChildren>> = () => (
    <>
        <div data-testid="mobile-header-skeleton">
            <StyledMobileCategoryBreadcrumbs />
            <StyledMobileCategoryHeader />
        </div>
        <StyledMobileContainer data-testid="mobile-card-skeleton">
            <StyledMobileCardSkeleton />
            <StyledMobileCardSkeleton />
            <StyledMobileCardSkeleton />
            <StyledMobileCardSkeleton />
            <StyledMobileCardSkeleton />
        </StyledMobileContainer>
    </>
);

const CategoryPageLoading: FC<PropsWithChildren<PropsWithChildren>> = () => {
    const isMobile = useIsMobile();

    return (
        <StyledLoadingPageWrapper data-testid="category-page-loading">
            {isMobile ? <MobileHeaderSkeleton /> : <DesktopHeaderSkeleton />}
            <StyledSwimlanesContainer data-testid="swimlane-skeleton">
                <SwimlaneSkeleton />
            </StyledSwimlanesContainer>
        </StyledLoadingPageWrapper>
    );
};

export default CategoryPageLoading;
