import {FC, PropsWithChildren} from 'react';

import type {IPromo} from 'client/views/components/home-page/merchandising-card';

import FeaturedCollectionSwimlane from '../home-page/swimlanes/featured-collection-swimlane';

interface IFeaturedCollectionProps {
    collectionId: number;
    promo?: IPromo;
    lazyload?: boolean;
}

const SanityCollectionSwimlane: FC<PropsWithChildren<PropsWithChildren<IFeaturedCollectionProps>>> = (props) => {
    const {collectionId, lazyload, promo} = props;

    return <FeaturedCollectionSwimlane collectionId={collectionId} lazyload={lazyload} promo={promo} />;
};

export default SanityCollectionSwimlane;
