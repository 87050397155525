import {FC, PropsWithChildren} from 'react';

import {useFeatureToggle} from 'client/context/feature-toggle';
import NextImage from 'client/views/components/next-image-loader';
import {PROMO_CARD_SIZING} from 'client/enums/feature-toggle-names';
import {useInViewEvent} from 'client/hooks/use-in-view-event';
import {
    reportSwimlanePromoCardEvolyticsImpression,
    reportSwimlanePromoCardEvolyticsClick
} from 'client/services/analytics-service';
import {useCustomer} from 'client/hooks/customer-hooks';
import {getBrandLandingPage_allCmsSharedBrandLandingPage_body_CmsProductCollection_promo as ICmsPromo} from 'autogen/getBrandLandingPage';

import AislesOnlineLink from '../aisles-online-link';

import styles from './merchandising-card.module.css';

export type IPromo = Omit<ICmsPromo, '__typename'>;

interface IProps {
    promo?: IPromo | null;
    index?: number;
}

const MerchandisingCard: FC<PropsWithChildren<PropsWithChildren<IProps>>> = ({promo, index}) => {
    const customer = useCustomer();

    const {featureEnabled} = useFeatureToggle();
    const isNewPromoSizingEnabled = featureEnabled(PROMO_CARD_SIZING);
    const {ref} = useInViewEvent({triggerOnce: true}, () =>
        reportSwimlanePromoCardEvolyticsImpression(promo, customer)
    );

    if (!promo || promo.enabled === false) {
        return null;
    }

    const {heading, imageUrl, linkText, linkUrl} = promo;

    return (
        <article aria-posinset={index} aria-setsize={-1} ref={ref} style={{display: 'inline'}}>
            <AislesOnlineLink
                alwaysVisible
                handleOnPromoClick={() => reportSwimlanePromoCardEvolyticsClick(promo, customer)}
                linkUrl={linkUrl}
                overrideLinkStyles
                testId="featured-collection-promo-clickable"
            >
                <div
                    className={`${styles.promoContainer} ${
                        isNewPromoSizingEnabled ? styles.promoContainerNew : styles.promoContainerOld
                    }`}
                    data-testid="promo-card"
                >
                    <div className={styles.imageAndText}>
                        <NextImage
                            alt={heading ?? 'merchandising-card'}
                            data-testid="featured-collection-promo-image"
                            height={isNewPromoSizingEnabled ? 204 : 244}
                            src={imageUrl}
                            width={isNewPromoSizingEnabled ? 272 : 244}
                        />
                        <div className={styles.textContainer}>
                            {heading && (
                                <span className={styles.headingText} id={`merchandising-card-heading-text${index}`}>
                                    {heading}
                                </span>
                            )}
                        </div>
                    </div>
                    <AislesOnlineLink
                        linkUrl={linkUrl}
                        style={{
                            color: 'var(--color-brand--primary)',
                            marginBottom: '36px',
                            marginTop: '10px'
                        }}
                        testId="featured-collection-promo-link"
                    >
                        {linkText}
                    </AislesOnlineLink>
                </div>
            </AislesOnlineLink>
        </article>
    );
};

export default MerchandisingCard;
