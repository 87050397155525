import {useWindowSize} from '../views/components/home-page/hooks';
import {ScreenSize} from '../enums/screen-size';

const useIsMobile = (): boolean => {
    const {width} = useWindowSize();

    return Boolean(width && width <= ScreenSize.MOBILE_MAX_WIDTH);
};

export {useIsMobile};
