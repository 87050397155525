import {gql} from '@apollo/client';

import {cmsImageWithAlt} from '../fragments/sanity-fragments';

import {productFragment} from './product-queries';

const ProductGroupFragment = gql`
    fragment IProductGroupFragment on productGroup {
        productGroupId
        name
    }
`;

const ProductGroupImageFragment = gql`
    fragment IProductGroupImageFragment on productGroup_images {
        uri
        isPrimary
    }
`;

export const cmsMarketingAOWebDetailSectionFragment = gql`
    ${cmsImageWithAlt}

    fragment ICmsMarketingAOWebDetailSection on CmsMarketingAOWebDetailSection {
        _key
        _type
        title
        subtext
        image {
            ...IImageWithAlt
        }
    }
`;

const cmsMarketingAOWebDetailSectionProductGrid = gql`
    fragment ICmsMarketingAOWebDetailSectionProductGrid on CmsMarketingAOWebDetailSectionProductGrid {
        _key
        _type
    }
`;

export const featuredCollectionDetailQuery = gql`
    ${ProductGroupFragment}
    ${ProductGroupImageFragment}

    query FeaturedCollectionDetailQuery($productGroupId: Int!) {
        productGroup(productGroupId: $productGroupId) {
            ...IProductGroupFragment
            images {
                ...IProductGroupImageFragment
            }
        }
    }
`;

export const featuredCollectionProductGroupQuery = gql`
    ${productFragment}
    query FeaturedCollectionQuery(
        $productGroupId: Int!
        $storeId: Int!
        $pageSize: Int
        $page: Int = 1
        $pickupLocationHasLocker: Boolean = false
        $sort: String = "priority"
        $order: String = "asc"
        $targeted: Boolean = false
        $retailItemEnabled: Boolean = false
        $locationIds: [ID!]!
        $wicEnabled: Boolean = false
    ) {
        productGroup(productGroupId: $productGroupId) {
            productGroupId
            name
        }
        pageableProductGroupStoreProducts: productGroupProducts(
            productGroupId: $productGroupId
            pageSize: $pageSize
            page: $page
            sort: $sort
            order: $order
        ) {
            page
            nextPage
            productGroupProducts {
                productGroupId
                productId
                product {
                    ...IProductFragment
                }
            }
        }
    }
`;

export const getSanityCollectionPageByIdQuery = gql`
    ${cmsMarketingAOWebDetailSectionFragment}
    ${cmsMarketingAOWebDetailSectionProductGrid}

    query GetSanityCollectionPageById($collectionId: String) {
        allCmsMarketingAOWebCollectionPage(
            limit: 1
            sort: {publishedAt: DESC}
            where: {_: {is_draft: false}, collectionID: {eq: $collectionId}}
        ) {
            body {
                ...ICmsMarketingAOWebDetailSection
                ...ICmsMarketingAOWebDetailSectionProductGrid
            }
        }
    }
`;
