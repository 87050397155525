import {FC, PropsWithChildren} from 'react';

import {useClassnames} from '../../../../hooks/use-classnames';
import {ElementProps} from '../../../../types/html-element-props';

import styles from './home-page-swimlane-styles.module.css';

interface IStyledHeader extends ElementProps<HTMLHeadingElement | HTMLParagraphElement> {
    as?: 'h2';
}

export const StyledHeader: FC<PropsWithChildren<PropsWithChildren<IStyledHeader>>> = ({
    as,
    children,
    className,
    ...props
}) => {
    const styledHeaderClassname = useClassnames(styles, {
        styledHeader: true,
        [`${className}`]: Boolean(className)
    });

    if (as === 'h2') {
        return (
            <h2 {...props} className={styledHeaderClassname}>
                {children}
            </h2>
        );
    }

    return (
        <p {...props} className={styledHeaderClassname}>
            {children}
        </p>
    );
};

export const SwimlaneWrapper: FC<PropsWithChildren<PropsWithChildren<ElementProps<HTMLDivElement>>>> = ({
    children,
    className,
    ...props
}) => {
    const swimlaneWrapperClassname = useClassnames(styles, {
        swimLaneWrapper: true,
        [`${className}`]: Boolean(className)
    });

    return (
        <div {...props} className={swimlaneWrapperClassname}>
            {children}
        </div>
    );
};

export const SwimLaneHeader: FC<PropsWithChildren<PropsWithChildren<ElementProps<HTMLDivElement>>>> = ({
    children,
    className,
    ...props
}) => {
    const swimlaneHeaderClassname = useClassnames(styles, {
        swimLaneHeader: true,
        [`${className}`]: Boolean(className)
    });

    return (
        <div {...props} className={swimlaneHeaderClassname}>
            {children}
        </div>
    );
};
