import {gql} from '@apollo/client';

export const cmsImageWithAlt = gql`
    fragment IImageWithAlt on CmsImageWithAlt {
        alt
        asset {
            _id
            assetId
            description
            extension
            label
            mimeType
            originalFilename
            path
            sha1hash
            size
            source {
                id
                name
                url
            }
            title
            url
        }
    }
`;

const displayFlagsFragment = gql`
    fragment IDisplayFlags on CmsDisplayFlags {
        customerType
        hasFuelSaver
        hasHPlusMembership
        isAuthenticated
    }
`;

export const cmsCardSwimlaneFragment = gql`
    ${displayFlagsFragment}

    fragment ICmsCardSwimlane on CmsCardSwimlane {
        _type
        _key
        icon {
            alt
            asset {
                url
            }
        }
        seeAllLink
        subtitle
        title
        backgroundColor {
            hex
        }
        cardType {
            ... on CmsImageCardsWithLinks {
                _type
                cards {
                    _type
                    link
                    image {
                        alt
                        asset {
                            url
                        }
                    }
                }
            }

            ... on CmsCouponCategory {
                _type
                category
                cards {
                    __typename
                    couponId
                    offerState
                    imageUrl
                    description
                    clipEndDate
                    valueText
                }
            }

            ... on CmsDealsByAdId {
                _type
                adId
                cards(storeId: $storeId, fuelSaverCardNumber: $fuelSaverCardNumber) {
                    __typename
                    dealId
                    price
                    priceMultiple
                    beginDate
                    endDate
                    deal {
                        dealId
                        dealTitle
                        dealDescription
                        imageUri
                        varietiesCount
                        fuelSaverDescription
                        size
                    }
                }
            }

            ... on CmsStoreAdAndLocalAdCards {
                _type
                shouldShowLocalAd
                cards(storeId: $storeId) {
                    ... on storeAd {
                        __typename
                        corpId
                        storeAdId
                        storeId
                        imageUri
                        beginDate
                        endDate
                        ad {
                            adId
                            description
                        }
                    }
                    ... on localAd {
                        __typename
                        localAdId
                        title
                        startDate
                        endDate
                        pngFilePath
                        pdfFolderName
                    }
                }
            }
        }
        displayFlags {
            ...IDisplayFlags
        }
    }
`;

export const colorFragment = gql`
    fragment IColor on Color {
        hex
    }
`;

export const cmsTextBlockFragment = gql`
    ${colorFragment}
    ${displayFlagsFragment}

    fragment ITextBlock on CmsTextBlock {
        _type
        maxColumns
        text: textRaw
        textAlignment
        verticalDivider
        horizontalDivider
        backgroundColor {
            ...IColor
        }
        textColor {
            ...IColor
        }
        displayFlags {
            ...IDisplayFlags
        }
    }
`;

export const cmsButtonFragment = gql`
    fragment ICmsButton on CmsButton {
        _key
        buttonText
        href
        disabled
        testID
    }
`;

const cmsMarketingBannerOverlayFragment = gql`
    ${cmsTextBlockFragment}
    ${cmsButtonFragment}

    fragment ICmsMarketingBannerOverlay on CmsMarketingBannerOverlay {
        _key
        left
        top
        body {
            ...ICmsButton
            ...ITextBlock
        }
    }
`;

const cmsMarketingBannerImageFragment = gql`
    ${cmsMarketingBannerOverlayFragment}

    fragment ICmsMarketingBannerImageFragment on CmsMarketingBannerImage {
        alt
        asset {
            url
            metadata {
                dimensions {
                    height
                    width
                }
            }
        }
        overlays {
            ...ICmsMarketingBannerOverlay
        }
    }
`;

export const cmsMarketingBannerFragment = gql`
    ${cmsMarketingBannerImageFragment}
    ${colorFragment}

    fragment ICmsMarketingBanner on CmsMarketingBanner {
        _key
        __typename
        _type
        name
        webImage {
            ...ICmsMarketingBannerImageFragment
        }
        tabletImage {
            ...ICmsMarketingBannerImageFragment
        }
        mobileImage {
            ...ICmsMarketingBannerImageFragment
        }
        bgColor {
            ...IColor
        }
        secondaryBgColor {
            ...IColor
        }
        href
    }
`;
